import React, { useState } from "react";
import { useEffect } from "react";
import "/node_modules/react-resizable/css/styles.css";
import "./index.css";
import { BEARER, API } from "../../constant";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../../helpers";
import { useAuthContext } from "../../context/AuthContext";
import { getToken } from "../../helpers.js";
// import "//public/4DS Views/example/js/fdvWebplayer/viewer.js"
import "react-horizontal-scrolling-menu/dist/styles.css";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Card } from "./card";

const SocialCards = () => {
  function Arrow({ children, disabled, onClick }) {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        style={{
          cursor: "pointer",
          justifyContent: "center",
          opacity: disabled ? "0" : "1",
          userSelect: "none",
          height: "2.1875em",
          width: "2.1875em",
          borderRadius: "1.5625em",
          margin: "0.625em",
          marginTop: "3.125em",
        }}
      >
        {children}
      </button>
    );
  }
  var [index, setIndex] = useState(0);
  var [DataList, setDataList] = useState([
    {
      id: "",
      attributes: {
        Gender: "",
        category: "",
        name: "",
        description: "",
        Style: "",
        Subject: "",
        isFavourites: "",
        gallery: {
          imageUrl: "",
          modelUrl: "",
        },
      },
    },
  ]);
  var [CategoryList, setCategoryList] = useState([
    {
      id: "",
      attributes: {
        Gender: "",
        category: "",
        name: "",
        description: "",
        Style: "",
        Subject: "",
        isFavourites: "",
        gallery: {
          imageUrl: "",
          modelUrl: "",
        },
      },
    },
  ]);
  var [GenderList, setGenderList] = useState([
    {
      id: "",
      attributes: {
        Gender: "",
        category: "",
        name: "",
        description: "",
        Style: "",
        Subject: "",
        isFavourites: "",
        gallery: {
          imageUrl: "",
          modelUrl: "",
        },
      },
    },
  ]);
  var [FavList, setFavList] = useState([
    {
      id: "",
      attributes: {
        Gender: "",
        category: "",
        name: "",
        description: "",
        Style: "",
        Subject: "",
        isFavourites: "",
        gallery: {
          imageUrl: "",
          modelUrl: "",
        },
      },
    },
  ]);
  var [metaData, setMetaData] = useState({
    pagination: {
      page: 2,
      pageSize: 25,
      pageCount: 3,
      total: 52,
    },
  });
  const [error, setError] = useState("");
  var once = true;
  const { user, setUser } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken();
    setUser(undefined);
    navigate("/", { replace: true });
  };

  var [handleButton, setHandelButton] = useState(false);
  var [handleCategory, sethandleCategory] = useState(false);
  var [handeFav, setFav] = useState(false);
  var [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);


  const postData = async (prob) => {
    fetch("https://srv522636.hstgr.cloud/api/model-details/" + prob.id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${BEARER} ` + getToken(),
      },
      method: "PUT",
      body: JSON.stringify({
        data: { isFavourites: prob.attributes.isFavourites },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setfavIndex(0);
      })
      .catch((err) => console.log(err));
  };

  const fetchData = () => {
    fetch(`${API}` + "/model-details?pagination[page]=" + currentPage, {
      headers: { Authorization: `${BEARER} ` + getToken() },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.error) {
          throw data?.error;
        } else {
          var ApiInfo = data.data;
          console.log(ApiInfo);
          var meta = data.meta;
          setMetaData(meta);
          if (once) {
            once = false;
            DataList.pop(0);
            FavList.splice(0, FavList.length);
          }
          ApiInfo.map((e) => {
            DataList.push({
              id: e.id,
              attributes: {
                category: e.attributes.category,
                name: e.attributes.name,
                Gender: e.attributes.Gender,
                description: e.attributes.description,
                Style: e.attributes.Style,
                Subject: e.attributes.Subject,
                isFavourites: e.attributes.isFavourites,
                gallery: {
                  imageUrl: e.attributes.gallery.imageUrl,
                  modelUrl: e.attributes.gallery.modelUrl,
                },
              },
            });
            if (e.attributes.isFavourites === true) {
              FavList.push({
                id: e.id,
                attributes: {
                  category: e.attributes.category,
                  name: e.attributes.name,
                  Gender: e.attributes.Gender,
                  description: e.attributes.description,
                  Style: e.attributes.Style,
                  Subject: e.attributes.Subject,
                  isFavourites: e.attributes.isFavourites,
                  gallery: {
                    imageUrl: e.attributes.gallery.imageUrl,
                    modelUrl: e.attributes.gallery.modelUrl,
                  },
                },
              });
            }
          });
          console.log(FavList);
          if (currentPage < metaData.pagination.pageCount) {
            setCurrentPage(currentPage++);
            fetchData();
            CategoryList.splice(0, CategoryList.length);
            DataList.map((e) => {
              if (DataList[0].attributes.category === e.attributes.category)
                CategoryList.push({
                  id: e.id,
                  attributes: {
                    category: e.attributes.category,
                    name: e.attributes.name,
                    Gender: e.attributes.Gender,
                    description: e.attributes.description,
                    Style: e.attributes.Style,
                    Subject: e.attributes.Subject,
                    isFavourites: e.attributes.isFavourites,
                    gallery: {
                      imageUrl: e.attributes.gallery.imageUrl,
                      modelUrl: e.attributes.gallery.modelUrl,
                    },
                  },
                });
            });

            Gender();
            handleLoad(0);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setError(error?.message ?? "Something went wrong!");
      });
      if(FavList.length<=0){
        setfavIsEmpty(true)
      }else{
        setfavIsEmpty(false)
      }
  };

  window.parent.addEventListener("message", (e) => {
    if (e.data.sender === "logout") {
      updateHamMenu(false);
    }
  });

  window.parent.addEventListener("message", (e) => {
    if (e.data.sender === "loading") {
      if (e.data.message === "start") {
        setloadig_class("burger-bar visiblex");
        console.log("start");
      }
      if (e.data.message === "complete") {
        setloadig_class("burger-bar hiddenx");
        console.log("complete");
      }
    }
  });

  window.parent.addEventListener("message", (e) => {
    if (e.data.sender === "menu") {
      if (e.data.message === "visible") {
        if (!collections) {
          updateMenu(false);
          updateFavMenu(true);
        }
        if (collections) {
          updateMenu(true);
          updateFavMenu(false);
        }
        setfavMenuClicked(true);
      }
      if (e.data.message === "hidden") {
        updateMenu(true);
        updateHamMenu(true);
        updateFavMenu(true);
        setfavMenuClicked(false);
      }
    }
  });
  const [burger_class, setBurger_class] = useState("burger-bar hiddenx");
  const [ham_class, setHam_class] = useState("burger-bar hiddenx");
  const [loading, setloadig_class] = useState("burger-bar hiddenx");
  const [menu_class, setmenu_class] = useState("menu hidden");
  const [View_class, setView_class] = useState("menu hidden");
  const [favMenu_class, setfavMenu_class] = useState("menu hidden");
  var [liked, setliked] = useState("unlike");
  var [menuopen, setfavMenuClicked] = useState(false);
  var [collections, setcollections] = useState(false);
  var [isLikeClicked, setLikeClicked] = useState(false);
  var[isFavouritesIsEmpty,setfavIsEmpty]=useState(true);
  var[isGenderListEmpty,setGenderlistEmpty]=useState(true);
  let valprob = false;
  // ---------------------------------------------------------------------------

  function CheckForFav(index) {
    if(GenderList.length!=0){
    var isFav = GenderList[index].attributes.isFavourites;
    if (valprob) {

      console.log("fav index : " + index);
      if (isFav) {
        setliked("like");
        setLikeClicked(isFav);
      } else {
        setliked("unlike");
        setLikeClicked(isFav);
      }
    }
      console.log(GenderList[index].attributes.isFavourites);
    }
    if(!valprob){
      if(FavList.length!=0){
        if(FavList[favIndex].attributes.isFavourites){
          setliked("like")
          setLikeClicked(isFav)
        }
        if(!FavList[favIndex].attributes.isFavourites){
          setliked("unlike")
          setLikeClicked(isFav)
        }
        console.log(FavList[favIndex].attributes.isFavourites);
      }
    }
  }

  function AddOrRemoveFromFav(prob) {
    if (prob) {
      if (!isLikeClicked) {
        setliked("like");

        if(FavList.length<=0){
          setfavIsEmpty(true)
        }else{
          setfavIsEmpty(false)
        }
        var removeElement=GenderList[index];
        var DataListIndex= DataList.findIndex((x) => x.id === removeElement.id)
        DataList[DataListIndex].attributes.isFavourites="true";
        console.log(DataListIndex);       
        
        var GenderListIndex= GenderList.findIndex((x) => x.id === removeElement.id)
        console.log(GenderListIndex);
        GenderList[GenderListIndex].attributes.isFavourites="true";
        
        var categoryListIndex= CategoryList.findIndex((x) => x.id === removeElement.id)
        console.log(categoryListIndex);
        CategoryList[categoryListIndex].attributes.isFavourites="true";

        FavList.push({id: GenderList[index].id,attributes:{category: GenderList[index].attributes.category,name:  GenderList[index].attributes.name,
          Gender: GenderList[index].attributes.Gender,
          description:  GenderList[index].attributes.description,
          Style: GenderList[index].attributes.Style,
          Subject: GenderList[index].attributes.Subject,
          isFavourites: GenderList[index].attributes.isFavourites,
          gallery: {
            imageUrl:  GenderList[index].attributes.gallery.imageUrl,
            modelUrl:  GenderList[index].attributes.gallery.modelUrl,}}})
      }
      if (isLikeClicked) {
        setliked("unlike");

        var popIndex= FavList.findIndex((x) => x.id === GenderList[index].id)
        console.log(popIndex);
        FavList.pop(popIndex);
        if(FavList.length<=0){
          setfavIsEmpty(true)
        }else{
          setfavIsEmpty(false)
        }
      }
      GenderList[index].attributes.isFavourites=!isLikeClicked;
    }
    if (!prob) {
      FavList[favIndex].attributes.isFavourites=false;
      var removeElement=FavList[favIndex];
      console.log(removeElement);
      
      var DataListIndex= DataList.findIndex((x) => x.id === removeElement.id)
        DataList[DataListIndex].attributes.isFavourites=false;
        console.log(DataListIndex);       
        
        var GenderListIndex= GenderList.findIndex((x) => x.id === removeElement.id)
        console.log(GenderListIndex);

        if(GenderListIndex===-1){

        }
        else{
          GenderList[GenderListIndex].attributes.isFavourites=false;
        }
        
        var categoryListIndex= CategoryList.findIndex((x) => x.id === removeElement.id)
        console.log(categoryListIndex);

        if(GenderListIndex===-1){

        }
        else{
        CategoryList[categoryListIndex].attributes.isFavourites=false;
        }
      postData(removeElement);
        setliked("unlike");

        var popIndex= FavList.findIndex((x) => x.id === removeElement.id)
        console.log(popIndex);
        console.log(FavList[popIndex]);
        FavList.splice(popIndex,popIndex+1);
        
        console.log(FavList);
        if(FavList.length<=0){
          setfavIsEmpty(true)
        }else{
          setfavIsEmpty(false)
        }
        setfavIndex(0);
        CheckForFav(favIndex);
    }
    setLikeClicked(!isLikeClicked);
  }
  const updateQuitMenu = (prob) => {
    if (prob === false) {
      setBurger_class("burger-bar visiblex");
    } else {
      setBurger_class("burger-bar hiddenx");
    }
  };
  const updateMenu = (prob) => {
    if (prob === false) {
      setmenu_class("menu visible");
      valprob = true;
      CheckForFav(index);
    } else {
      setmenu_class("menu hidden");
    }
  };
  const updateHamMenu = (prob) => {
    if (prob === false) {
      setHam_class("burger-bar visiblep");
      setView_class("menu visible");
    } else {
      setHam_class("burger-bar hiddenx");
      setView_class("menu hidden");
    }
  };
  const updateFavMenu = (prob) => {
    if (prob === false) {
      setfavMenu_class("menu visible");
    } else {
      setfavMenu_class("menu hidden");
    }
  };
  function handleNextClick() {
    index = index + 1;
    if (index >= GenderList.length - 1) {
      index = GenderList.length - 1;
    }
    console.log("+ Current Gender : " + index);
    valprob = true;
    CheckForFav(index);
  }

  function handleImageClick(pram) {
    setIndex(pram);
    valprob = true;
    CheckForFav(pram);
  }

  function RightArrow() {
    const visibility = React.useContext(VisibilityContext);

    return (
      <Arrow
        disabled={false}
        onClick={() => {
          visibility.scrollNext();
          handleNextClick();
          setIndex(index);
        }}
      >
        {
          <img
            src="https://4dviewer.prelight3d.co.uk/Resources/closebtn1.png"
            style={{
              height: "2.1875em",
              width: "2.1875em",
              borderRadius: "1.5625em",
              backgroundColor: "#1E1E1E",
              borderWidth: "0em",
              margin: "-0.5em",
              marginTop: "-0.75em",
            }}
          />
        }
      </Arrow>
    );
  }
  function LeftArrow() {
    const visibility = React.useContext(VisibilityContext);

    return (
      <Arrow
        disabled={false}
        onClick={() => {
          visibility.scrollPrev();
          handlePreviousClick();
          setIndex(index);
        }}
      >
        <img
          src="https://4dviewer.prelight3d.co.uk/Resources/closebtn2.png"
          style={{
            height: "2.1875em",
            width: "2.1875em",
            borderRadius: "1.5625em",
            backgroundColor: "#1E1E1E",
            borderWidth: "0em",
            margin: "-0.5em",
            marginTop: "-0.75em",
          }}
        />
      </Arrow>
    );
  }

  function handlePreviousClick() {
    index = index - 1;
    if (index <= 0) {
      index = 0;
    }
    console.log(" - Current Gender : " + index);
    valprob = true;
    CheckForFav(index);
  }

  function ThumbnailLayout() {

    // var currentImageIndex = "";
    var imagePos = 0;
    function PlayerList() {
      if (GenderList[pos] === null) {
        return (
          <>
            <div>nothing to show</div>
          </>
        );
      } else {
        const arrayDataItems = GenderList.map((link, index) => (
          <img
            key={index}
            src={
              "https://srv522636.hstgr.cloud" + link.attributes.gallery.imageUrl
            }
            alt={link.attributes.gallery.imageUrl}
            style={{
              marginTop: "0em",
              height: "6.25em",
              width: "6.25em",
              marginLeft: "0.625em",
              borderRadius: "0.3125em",
              border: "0.21875em solid #D2188E",
              backgroundColor: "#D2188E",
              padding: "0.3125em",
            }}
            onClick={() => {
              // currentImageIndex = link;
              imagePos = GenderList.findIndex((x) => x.id === link.id);
              handleImageClick(imagePos);
              //CheckForFav()
            }}
          />
        ));
        return <ul>{arrayDataItems} </ul>;
      }
    }

    let sculpture = "https://4dviewer.prelight3d.co.uk/Resources/NoModel.png";
    {
      function Category() {
        var categoryList = [];
        var list = DataList.map((items, index) => {
          <option key={index} value={items.attributes.category}>
            {items.attributes.category.toUpperCase()}
          </option>;
          if (categoryList.includes(items.attributes.category)) {
          } else {
            categoryList.push(items.attributes.category);
          }
        });
        return (
          <select
            className="content"
            style={{ marginLeft: "0.625em", width: "50%", height: "2.16em" }}
            value={value}
            onChange={handleChange}
          >
            {categoryList.map((e, key) => {
              return (
                <option className="content"style={{fontSize:18}} key={key} value={e}>
                  {e.toUpperCase()}
                </option>
              );
            })}
          </select>
        );
      }
      if(isGenderListEmpty){

        sculpture =
        "https://srv522636.hstgr.cloud" +
        GenderList[index].attributes.gallery.imageUrl;
      }


      return (
        <div style={{ padding: "0.3125em" }}>
          <button
            style={{
              right: "0",
              height: "3.375em",
              width: "4.2em",
              marginLeft: "83%",
              borderRadius: "0.9375em",
              backgroundColor: "#00000000",
              border: "0.21875em solid #00000000",
            }}
            onClick={() => {
              updateHamMenu(false);
            }}
          >
            <img
              src="https://4dviewer.prelight3d.co.uk/Resources/Hamburger%20Icon1.png"
              alt="ham Menu"
              style={{
                height: "100%",
                borderRadius: "0.625em",
                width: "100%",
                color: "#000",
              }}
            />
          </button>
          <div
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "center",
              fontSize: 25,
              marginTop: "-2.16em",
              marginLeft: "-1.25em",
            }}
          >
            <div style={{ fontSize: 35 }} className="h1">
              CATALOGUE
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "center",
              fontSize: 20,
              marginTop: "1.08em",
            }}
          >
            <Category />
            <select
              className="content"
              value={selectedGender}
              onChange={handelGender}
              style={{ marginLeft: "0.625em", width: "50%", height: "2.16em",fontSize:20 }}
            >
              <option className="content"style={{fontSize:18}} value="All">
                ALL
              </option>

              <option className="content"style={{fontSize:18}} value="Male">
                MALE
              </option>

              <option className="content"style={{fontSize:18}} value="Female">
                FEMALE
              </option>
              <option className="content"style={{fontSize:18}} value="Others">
                OTHERS
              </option>
            </select>
            {/* <Gender/> */}
          </div>
          <div>
            <div
              style={{
                height: "20.25em",
                width: "16.8em",
                marginLeft: "5.54em",
                marginTop: "0.675em",
                borderRadius: "0.9375em",
                border: "0.21875em solid #D2188E",
              }}
            >
              <img
                src={sculpture}
                alt={sculpture}
                style={{
                  height: "100%",
                  borderRadius: "0.625em",
                  width: "100%",
                }}
              />
              <div>
                <div
                  style={{
                    height: "1.875em",
                    width: "1.875em",
                    marginTop: "-19.575em",
                    marginLeft: "14.4em",
                    position: "fixed",
                  }}
                  onClick={() => {
                    AddOrRemoveFromFav(true);
                    postData(GenderList[index])
                  }}
                >
                  <img
                    alt={liked}
                    className={liked}
                    src="https://4dviewer.prelight3d.co.uk/Resources/FavoriteFilledIcon.png"
                    style={{ backgroundColor: "#00000000", position: "fixed" }}
                  />
                  <img
                    alt="like"
                    className="unlike"
                    src="https://4dviewer.prelight3d.co.uk/Resources/FavoriteFilledIcongray.png"
                    style={{ backgroundColor: "#00000000", position: "fixed" }}
                  />
                  <img
                    alt="like"
                    src="https://4dviewer.prelight3d.co.uk/Resources/FavoriteOutlineIcon.png"
                    style={{
                      height: "1.875em",
                      width: "1.875em",
                      backgroundColor: "#00000000",
                      position: "fixed",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  function handleLoad(index) {
    if(GenderList.length!=0)
    window.parent.postMessage(
      {
        sender: "model",
        message: GenderList[index].attributes,
      },
      "*"
    );
  }

  function handleFavLoad(index) {
    if(FavList.length!=0){
      window.parent.postMessage(
        {
          sender: "model",
          message: FavList[index].attributes,
        },
        "*"
      );
    }
  }


  const [favIndex, setfavIndex] = useState(0);

  var [pos, SetPos] = useState(0);

  const getInitialState = () => {
    const value = "Orange";
    return value;
  };

  function Gender() {
    setIndex(0);
    console.log(GenderList);
    GenderList.splice(0, GenderList.length);
    CategoryList.map((e) => {
      if (selectedGender === "All") {
        GenderList.push({
          id: e.id,
          attributes: {
            category: e.attributes.category,
            name: e.attributes.name,
            Gender: e.attributes.Gender,
            description: e.attributes.description,
            Style: e.attributes.Style,
            Subject: e.attributes.Subject,
            isFavourites: e.attributes.isFavourites,
            gallery: {
              imageUrl: e.attributes.gallery.imageUrl,
              modelUrl: e.attributes.gallery.modelUrl,
            },
          },
        });
      }
      if (e.attributes.Gender === selectedGender) {
        GenderList.push({
          id: e.id,
          attributes: {
            category: e.attributes.category,
            name: e.attributes.name,
            Gender: e.attributes.Gender,
            description: e.attributes.description,
            Style: e.attributes.Style,
            Subject: e.attributes.Subject,
            isFavourites: e.attributes.isFavourites,
            gallery: {
              imageUrl: e.attributes.gallery.imageUrl,
              modelUrl: e.attributes.gallery.modelUrl,
            },
          },
        });
      }
    });
  }
  const [value, setValue] = useState(getInitialState);

  const [selectedGender, setGender] = useState("All");

  function handelGender(val) {
    setIndex(0);
    setGender(val.target.value);
    console.log(val.target.value);
    GenderList.splice(0, GenderList.length);
    CategoryList.map((e) => {
      if (e.attributes.Gender === val.target.value) {
        GenderList.push({
          id: e.id,
          attributes: {
            category: e.attributes.category,
            name: e.attributes.name,
            Gender: e.attributes.Gender,
            description: e.attributes.description,
            Style: e.attributes.Style,
            Subject: e.attributes.Subject,
            isFavourites: e.attributes.isFavourites,
            gallery: {
              imageUrl: e.attributes.gallery.imageUrl,
              modelUrl: e.attributes.gallery.modelUrl,
            },
          },
        });
      }
      if (val.target.value === "All") {
        GenderList.push({
          id: e.id,
          attributes: {
            category: e.attributes.category,
            name: e.attributes.name,
            Gender: e.attributes.Gender,
            description: e.attributes.description,
            Style: e.attributes.Style,
            Subject: e.attributes.Subject,
            isFavourites: e.attributes.isFavourites,
            gallery: {
              imageUrl: e.attributes.gallery.imageUrl,
              modelUrl: e.attributes.gallery.modelUrl,
            },
          },
        });
      }
    });
    if (GenderList.length === 0) {
      setGenderlistEmpty(false)
        }else{
          setGenderlistEmpty(true)
        }

    valprob = true;
    CheckForFav(0);
    handleLoad(0);
  }
  const handleChange = (c) => {
    setIndex(0);
    setValue(c.target.value);
    CategoryList.splice(0, CategoryList.length);
    DataList.map((e) => {
      if (c.target.value === e.attributes.category)
        CategoryList.push({
          id: e.id,
          attributes: {
            category: e.attributes.category,
            name: e.attributes.name,
            description: e.attributes.description,
            Style: e.attributes.Style,
            Subject: e.attributes.Subject,
            isFavourites: e.attributes.isFavourites,
            Gender: e.attributes.Gender,
            gallery: {
              imageUrl: e.attributes.gallery.imageUrl,
              modelUrl: e.attributes.gallery.modelUrl,
            },
          },
        });
    });
    // setGender("All");
    GenderList.splice(0, GenderList.length);
    CategoryList.map((e) => {
      if (e.attributes.Gender === selectedGender) {
        GenderList.push({
          id: e.id,
          attributes: {
            category: e.attributes.category,
            name: e.attributes.name,
            Gender: e.attributes.Gender,
            description: e.attributes.description,
            Style: e.attributes.Style,
            Subject: e.attributes.Subject,
            isFavourites: e.attributes.isFavourites,
            gallery: {
              imageUrl: e.attributes.gallery.imageUrl,
              modelUrl: e.attributes.gallery.modelUrl,
            },
          },
        });
      }
      if (selectedGender === "All") {
        GenderList.push({
          id: e.id,
          attributes: {
            category: e.attributes.category,
            name: e.attributes.name,
            Gender: e.attributes.Gender,
            description: e.attributes.description,
            Style: e.attributes.Style,
            Subject: e.attributes.Subject,
            isFavourites: e.attributes.isFavourites,
            gallery: {
              imageUrl: e.attributes.gallery.imageUrl,
              modelUrl: e.attributes.gallery.modelUrl,
            },
          },
        });
      }
    });
    if (GenderList.length === 0) {
      setGenderlistEmpty(false)
        }else{
          setGenderlistEmpty(true)
        }

    valprob = true;
    CheckForFav(0);
    handleLoad(0);
  };

  let backgroundimg = "";
  backgroundimg =
    "https://4dviewer.prelight3d.co.uk/Resources/VendorPanelCropped.png";
  var categoryList = [];
   DataList.map((items,key) => {
    <option key={key} value={items.attributes.category}>
      {items.attributes.category}
    </option>;
    if (categoryList.includes(items.attributes.category)) {
    } else {
      categoryList.push(items.attributes.category);
    }
  });

  function handleFavNextClick() {
    setfavIndex(favIndex + 1);
    if (favIndex >= FavList.length - 1) {
      setfavIndex(FavList.length - 1);
    }
    valprob = false;
    CheckForFav(favIndex);
  }

  function handleFavPreviousClick() {
    setfavIndex(favIndex - 1);
    if (favIndex <= 0) {
      setfavIndex(0);
    }
    valprob = false;
    CheckForFav(favIndex);
  }

  function handleFavImageClick(pram) {
    CheckForFav(pram);
    valprob = false;
    setfavIndex(pram);
  }

  function RightArrowFav() {
    const visibility = React.useContext(VisibilityContext);

    return (
      <Arrow
        disabled={false}
        onClick={() => {
          visibility.scrollNext();
          handleFavNextClick();
        }}
      >
        <img
          src="https://4dviewer.prelight3d.co.uk/Resources/closebtn1.png"
          style={{
            height: "2.1875em",
            width: "2.1875em",
            borderRadius: "1.5625em",
            backgroundColor: "#1E1E1E",
            borderWidth: "0em",
            margin: "-0.5em",
            marginTop: "-0.75em",
          }}
        />
      </Arrow>
    );
  }
  function LeftArrowFav() {
    const visibility = React.useContext(VisibilityContext);

    return (
      <Arrow
        disabled={false}
        onClick={() => {
          visibility.scrollPrev();
          handleFavPreviousClick()
        }}
      >
        <img
          src="https://4dviewer.prelight3d.co.uk/Resources/closebtn2.png"
          style={{
            height: "2.1875em",
            width: "2.1875em",
            borderRadius: "1.5625em",
            backgroundColor: "#1E1E1E",
            borderWidth: "0em",
            margin: "-0.5em",
            marginTop: "-0.75em",
          }}
        />
      </Arrow>
    );
  }

  function FavListLayout() {
  
    let sculpture = "";
    sculpture =
      "https://srv522636.hstgr.cloud" +
      FavList[favIndex].attributes.gallery.imageUrl;

    return (
      <div style={{ padding: "0.3125em" }}>
        <button
          style={{
            right: "0",
            height: "3.375em",
            width: "4.2em",
            marginLeft: "83%",
            borderRadius: "0.9375em",
            backgroundColor: "#00000000",
            border: "0.21875em solid #00000000",
          }}
          onClick={() => {
            updateHamMenu(false);
          }}
        >
          <img
            src="https://4dviewer.prelight3d.co.uk/Resources/Hamburger%20Icon1.png"
            alt="ham Menu"
            style={{
              height: "100%",
              borderRadius: "0.625em",
              width: "100%",
              color: "#000",
            }}
          />
        </button>
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "center",
            fontSize: 25,
            marginTop: "-2.16em",
            marginLeft: "-1.25em",
          }}
        >
          <div style={{ fontSize: 35 }} className="h1">
            FAVOURITES
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "center",
            fontSize: 25,
            marginTop: "0.864em",
          }}
        >
        </div>
        <div>
          <div
            style={{
              height: "20.25em",
              width: "16.8em",
              marginLeft: "5.54em",
              marginTop: "3.375em",
              borderRadius: "0.9375em",
              border: "0.21875em solid #D2188E",
            }}
          >
            <img
              src={sculpture}
              alt={sculpture}
              style={{
                height: "100%",
                borderRadius: "0.625em",
                width: "100%",
              }}
            />
            <div
              style={{
                height: "1.875em",
                width: "1.875em",
                marginTop: "-19.575em",
                marginLeft: "14.4em",
                position: "fixed",
              }}
              onClick={() => {
                AddOrRemoveFromFav(false);
              }}
            >
              <img
                alt={liked}
                className={liked}
                src="https://4dviewer.prelight3d.co.uk/Resources/FavoriteFilledIcon.png"
                style={{ backgroundColor: "#00000000", position: "fixed" }}
              />
              <img
                alt="like"
                src="https://4dviewer.prelight3d.co.uk/Resources/FavoriteOutlineIcon.png"
                style={{
                  height: "1.875em",
                  width: "1.875em",
                  backgroundColor: "#00000000",
                  position: "fixed",
                }}
              />
            </div>
          </div>
        </div>
        </div>
    );
  }
  return (
    <div style={{ backgroundColor: "#D2188E" }}>
      {handleCategory === false ? (
        <div>
          <div
            style={{
              height: "100vh",
              width: "100vw",
              justifyContent: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              backgroundImage: `url("https://4dviewer.prelight3d.co.uk/Resources/MainPanel.svg")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                border: "0.21875em solid #D2188E",
                borderColor: "#D2188E",
                borderRadius: "0.9375em",
                marginBottom: "1.688em",
              }}
            >
              <img
                src="https://4dviewer.prelight3d.co.uk/Resources/logo1.png"
                alt="1"
                style={{
                  height: "7.8125em",
                  width: "12.5em",
                  borderRadius: "0.9375em",
                  padding: "0.1875em",
                }}
              />
            </div>
            <div style={{ margin: "1.125em" }}>
              <div style={{ fontSize: 24 }}>Category</div>
              <select
                style={{
                  border: "0.175em solid #D2188E",
                  borderColor: "#D2188E",
                  borderRadius: "0.9375em",
                  height: "3.25em",
                  width: "20.875em",
                  fontSize: 20,
                }}
                value={value}
                onChange={handleChange}
              >
                {categoryList.map((e, key) => (
                  <option style={{ fontSize: 18 }} key={key} value={e}>
                    {e.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ margin: "1.125em" }}>
              <div style={{ fontSize: 24 }}>Gender</div>

              <select
                style={{
                  border: "0.175em solid #D2188E",
                  borderColor: "#D2188E",
                  borderRadius: "0.9375em",
                  height: "3.25em",
                  width: "20.875em",
                  fontSize: 20,
                }}
                value={selectedGender}
                onChange={handelGender}
              >
                <option style={{ fontSize: 18 }} value="All">
                  ALL
                </option>

                <option style={{ fontSize: 18 }} value="Male">
                  MALE
                </option>

                <option style={{ fontSize: 18 }} value="Female">
                  FEMALE
                </option>

                <option style={{ fontSize: 18 }} value="Others">
                  OTHERS
                </option>
              </select>
            </div>
            <div>
              <button
                className="textontent"
                style={{
                  border: "0.11538461538461539em solid #D2188E",
                  borderColor: "#D2188E",
                  borderRadius: "0.5769230769230769em",
                  height: "2.3076923076923075em",
                  width: "6.153846153846154em",
                  borderWidth: "0.11538461538461539em",
                  margin: "1.038em",
                  backgroundColor: "black",
                  color: "white",
                  fontSize: 26,
                }}
                onClick={() => {
                  sethandleCategory(true);
                  setTimeout(() => {
                    handleLoad(index);
                  }, [200]);
                }}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          
          <div>
            {
              <div style={{ width: "99.5vw", height: "vh" }}>
                <div>
                  <iframe src="threeJSModelViewer/ModelViewer.html" />
                </div>
                {isGenderListEmpty === false ? (<div className={menu_class}>
                  <div
                    style={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundImage: `url(${backgroundimg})`,
                      height: "100Vh",
                    }}
                  >
                    <ThumbnailLayout />
                    <div
                      style={{
                        marginLeft: "1.2em",
                        marginRight: "1.2em",
                        marginTop: "1.35em",
                      }}
                    >
                      <ScrollMenu >
               <Card key={index}
               onClick={() => { }}
               title={"https://4dviewer.prelight3d.co.uk/Resources/AddMore.png"}
             />
             <Card key={index}
               onClick={() => { }}
               title={"https://4dviewer.prelight3d.co.uk/Resources/AddMore.png"}
             />
          </ScrollMenu>
                    </div>
                    <hr
                      style={{
                        height: "0.506em",
                        backgroundColor: "#000",
                        marginTop: "1.35em",
                      }}
                    />
                    <div style={{ marginTop: "2.35em" }}>
                      <ul style={{ fontSize: 24, marginLeft: "-1.25em",marginTop:"5em",marginRight:"0.625em" ,display:"flex",flex:"0",alignContent:'center',alignItems:"center",justifyContent:"center"}}>
            No Description 
            Available </ul>
                    </div>
                    
                  </div>
                </div>):(
                <div className={menu_class}>
                  <div
                    style={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundImage: `url(${backgroundimg})`,
                      height: "100Vh",
                    }}
                  >
                    <ThumbnailLayout />
                    <div
                      style={{
                        marginLeft: "1.2em",
                        marginRight: "1.2em",
                        marginTop: "1.35em",
                      }}
                    >
                      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                        {GenderList.map((link, index) => (
                          <Card key={index}
                            onClick={() => {
                              console.log("link : " + link);
                              var imagePos = GenderList.findIndex(
                                (x) => x.id === link.id
                              );
                              handleImageClick(imagePos);
                            }}
                            title={
                              "https://srv522636.hstgr.cloud" +
                              link.attributes.gallery.imageUrl
                            }
                          />
                        ))}
                      </ScrollMenu>
                    </div>
                    <hr
                      style={{
                        height: "0.506em",
                        backgroundColor: "#000",
                        marginTop: "1.35em",
                      }}
                    />
                    <div style={{ marginTop: "2.35em" }}>
                      <div className="scrollable-div">
                        <ul style={{ fontSize: 24, marginLeft: "-1.25em",marginRight:"0.625em" }}>
                          From the {GenderList[index].attributes.category}{" "}
                          Category {GenderList[index].attributes.Subject}{" "}
                          presents a {GenderList[index].attributes.description}
                          <div>
                            {" "}
                            Style identified as{" "}
                            {GenderList[index].attributes.Style}
                          </div>
                          <div></div>
                        </ul>
                      </div>
                    </div>
                    <button
                      style={{
                        backgroundColor: "#1E1E1E",
                        height: "2.25em",
                        width: "8em",
                        marginLeft: "4.45em",
                        marginTop: "0.9em",
                        borderRadius: "0.625em",
                        borderColor: "#D2188E",
                        border: "0.14583333333333334em solid #D2188E",
                        fontSize: 24,
                        color: "white",
                      }}
                      onClick={() => {
                        handleLoad(index);
                      }}
                    >
                      {" "}
                      Apply Changes{" "}
                    </button>
                  </div>
                </div>
                )}

 {/*<--------------------------------------------------------------------------------------->*/}


                <div className={favMenu_class}>
                {isFavouritesIsEmpty===true?(<div style={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundImage: `url(${backgroundimg})`,
                      height: "100Vh",
                    }}>
                      <div>
                 <button
          style={{
            right: "0",
            height: "3.375em",
            width: "4.2em",
            marginLeft: "83%",
            borderRadius: "0.9375em",
            backgroundColor: "#00000000",
            border: "0.21875em solid #00000000",
          }}
          onClick={() => {
            updateHamMenu(false);
          }}
        >
          <img
            src="https://4dviewer.prelight3d.co.uk/Resources/Hamburger%20Icon1.png"
            alt="ham Menu"
            style={{
              height: "100%",
              borderRadius: "0.625em",
              width: "100%",
              color: "#000",
            }}
          />
        </button>
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "center",
            fontSize: 24,
            marginTop: "-2.25em",
            marginLeft: "-0.8333333333333334em",
          }}
        >
          <div style={{ fontSize: 35 }} className="h1">
            FAVOURITES
          </div>
        </div>
        <div className="content" style={{
            display: "flex",
            flex: "1",
            justifyContent: "center",
            fontSize: 25,
            marginTop: "0.864em",marginLeft:"0.768em"}}></div>
                      </div>
                      <div>
          <div
            style={{
              height: "20.25em",
              width: "16.8em",
              marginLeft: "5.84em",
              marginTop: "3.375em",
              borderRadius: "0.9375em",
              border: "0.21875em solid #D2188E",
            }}
          >
            <img
              src={"https://4dviewer.prelight3d.co.uk/Resources/NoModel.png"}
              alt={"NoModel"}
              style={{
                height: "100%",
                borderRadius: "0.625em",
                width: "100%",
              }}
            />
            <div
              style={{
                height: "1.875em",
                width: "1.875em",
                marginTop: "-19.575em",
                marginLeft: "14.4em",
                position: "fixed",
              }}
              onClick={() => {
                AddOrRemoveFromFav(false);
              }}
            >
              <img
                alt={liked}
                className={liked}
                src="https://4dviewer.prelight3d.co.uk/Resources/FavoriteFilledIcon.png"
                style={{ backgroundColor: "#00000000", position: "fixed" }}
              />
              
            </div>
          </div>
        </div>
        <div
          style={{ marginLeft: "3em", marginRight: "1.2em", marginTop: "1.35em" }}
        >
          <ScrollMenu >
               <Card key={index}
               onClick={() => { }}
               title={"https://4dviewer.prelight3d.co.uk/Resources/AddMore.png"}
             />
             <Card key={index}
               onClick={() => { }}
               title={"https://4dviewer.prelight3d.co.uk/Resources/AddMore.png"}
             />
          </ScrollMenu>
        </div>
                      <hr
           style={{
            height: "0.506em",
            backgroundColor: "#000",
            marginTop: "1.35em",
          }}
        />
        <div style={{ marginTop: "2.35em" }}>
            <ul style={{ fontSize: 24, marginLeft: "-1.25em",marginTop:"5em",marginRight:"0.625em" ,display:"flex",flex:"0",alignContent:'center',alignItems:"center",justifyContent:"center"}}>
            No Description 
            Available
            </ul>
        </div>
                      </div>): (
                  <div
                    style={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundImage: `url(${backgroundimg})`,
                      height: "100Vh",
                    }}
                  >
                    <FavListLayout />
                    <div
          style={{ marginLeft: "1.2em", marginRight: "1.2em", marginTop: "1.35em" }}
        >
          <ScrollMenu LeftArrow={LeftArrowFav} RightArrow={RightArrowFav}>
            {FavList.map((link, index) => (
               <Card key={index}
               onClick={() => {
                 console.log("link : " + link);
                 var imagePos = FavList.findIndex(
                   (x) => x.id === link.id
                 );
                 handleFavImageClick(imagePos);
               }}
               title={
                 "https://srv522636.hstgr.cloud" +
                 link.attributes.gallery.imageUrl
               }
             />
            ))}
          </ScrollMenu>
        </div>
        <hr
           style={{
            height: "0.506em",
            backgroundColor: "#000",
            marginTop: "1.35em",
          }}
        />
        <div style={{ marginTop: "2.35em" }}>
          <div className="scrollable-div">
            <ul style={{ fontSize: 24, marginLeft: "-1.25em",marginRight:"0.625em" }}>
              From the {FavList[favIndex].attributes.category} Category{" "}
              {FavList[favIndex].attributes.Subject} presents a{" "}
              {FavList[favIndex].attributes.description}
              <div>
                {" "}
                Style identified as {FavList[favIndex].attributes.Style}
              </div>
            </ul>
          </div>
        </div>
        <button
          style={{
            backgroundColor: "#1E1E1E",
            height: "2.25em",
            width: "8em",
            marginLeft: "4.45em",
            marginTop: "0.9em",
            borderRadius: "0.625em",
            borderColor: "#D2188E",
            border: "0.14583333333333334em solid #D2188E",
            fontSize: 24,
            color: "white",
          }}
          onClick={() => {
            handleFavLoad(favIndex);
          }}
        >
          {" "}
          Apply Changes{" "}
        </button>
                  </div>)}
                </div>
                <div className={View_class}>
                  <div
                    style={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundImage: `url(${backgroundimg})`,
                      height: "100Vh",
                      zIndex: 10,
                    }}
                  >
                    <button
                      style={{
                        right: "0",
                        height: "3.375em",
                        width: "4.2em",
                        marginLeft: "83%",
                        borderRadius: "0.9375em",
                        backgroundColor: "#00000000",
                        border: "0.21875em solid #00000000",
                      }}
                      onClick={() => {
                        updateHamMenu(true);
                      }}
                    >
                      <img
                        src="https://4dviewer.prelight3d.co.uk/Resources/Hamburger%20Icon1.png"
                        alt="ham Menu"
                        style={{
                          height: "100%",
                          borderRadius: "0.625em",
                          width: "100%",
                        }}
                      />
                    </button>

                    <img
                      src="https://4dviewer.prelight3d.co.uk/Resources/logo1.png"
                      alt="1"
                      style={{
                        height: "6.5625em",
                        width: "9.375em",
                        borderRadius: "0.9375em",
                        padding: "0.1875em",
                        top: "0",
                        left: "0",
                        position: "fixed",
                        marginLeft: "8.4em",
                      }}
                    />

                    <div style={{ marginTop: "3.375em" }}>
                      <button
                        className="hamMenuContent"
                        style={{
                          height: "2.25em",
                          width: "100%",
                          textAlign: "initial",
                          fontSize: 24,
                          marginLeft: "0.8em",
                        }}
                        onClick={() => {
                          setcollections(false);
                          if (!menuopen) {
                            updateHamMenu(true);
                            updateFavMenu(true);
                            updateMenu(true);
                          }
                          if (menuopen) {
                            updateHamMenu(true);
                            updateFavMenu(true);
                            updateMenu(false);
                            valprob = true;
                            CheckForFav(index);
                            handleLoad(index);
                          }
                        }}
                      >
                        Browse Catalogue
                      </button>
                    </div>
                    <div style={{ marginTop: "2.025em" }}>
                      <button
                        className="hamMenuContent"
                        style={{
                          height: "2.25em",
                          width: "100%",
                          textAlign: "initial",
                          fontSize: 24,
                          marginLeft: "0.8em",
                        }}
                        onClick={() => {
                          if (!menuopen) {
                            updateFavMenu(true);
                            updateHamMenu(true);
                            updateMenu(true);
                          }
                          if (menuopen) {
                            updateFavMenu(false);
                            updateHamMenu(true);
                            updateMenu(true);
                            valprob = false;
                            CheckForFav(0);
                            handleFavLoad(0)
                          }
                          setcollections(true);
                          if(FavList.length<=0){
                            setfavIsEmpty(true)
                          }else{
                            setfavIsEmpty(false)
                          }
                        }}
                      >
                        My Collections
                      </button>
                    </div>
                    <div style={{ marginTop: "2.025em" }}>
                      <button
                        style={{
                          backgroundColor: "#00000000",
                          border: "0.14583333333333334em solid #00000000",
                          height: "2.25em",
                          width: "100%",
                          textAlign: "initial",
                          fontSize: 24,
                          marginLeft: "0.8em",
                          color: "GrayText",
                        }}
                        disabled={true}
                        onClick={() => {}}
                      >
                        Place Order
                      </button>
                    </div>
                    <div style={{ marginTop: "2.025em" }}>
                      <button
                        style={{
                          backgroundColor: "#00000000",
                          border: "0.14583333333333334em solid #00000000",
                          height: "2.25em",
                          width: "100%",
                          textAlign: "initial",
                          fontSize: 24,
                          marginLeft: "0.8em",
                          color: "GrayText",
                        }}
                        disabled={true}
                      >
                        Contact Vendor
                      </button>
                    </div>
                    <div style={{ marginTop: "2.025em" }}>
                      <button
                        className="hamMenuContent"
                        style={{
                          height: "2.25em",
                          width: "100%",
                          textAlign: "initial",
                          fontSize: 24,
                          marginLeft: "0.8em",
                        }}
                        onClick={() => {
                          updateQuitMenu(false);
                        }}
                      >
                        Home
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  className={ham_class}
                  style={{
                    width: "100vw",
                    height: "100Vh",
                    zIndex: 10,
                    position: "fixed",
                    top: 0,
                    backgroundColor: "#00000000",
                    border: "0.21875em solid #00000000",
                  }}
                  onClick={() => {
                    updateHamMenu(true);
                  }}
                ></button>
                {/*<---------------------->*/}
                <div className={loading}>
                  <button
                    style={{
                      height: "100vh",
                      width: "100vw",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "white",
                    }}
                    onClick={() => {}}
                  >
                    <div
                      style={{
                        height: "15.625em",
                        width: "28.125em",
                        justifyContent: "center",
                        display: "flex",
                        borderRadius: "0.9375em",
                        backgroundColor: "#34BCF2",
                        paddingTop: "0.3125em",
                      }}
                    >
                      <div
                        style={{
                          height: "15em",
                          width: "27.5em",
                          justifyContent: "center",
                          display: "flex",
                          borderRadius: "0.9375em",
                          backgroundColor: "white",
                          paddingTop: "15%",
                        }}
                      >
                        <h1 style={{ fontSize: 54 }} className="heading">
                          PRELIGHT 3D
                        </h1>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "0.625em", fontSize: 24 }}
                      className="textontent"
                    >
                      Loading...
                    </div>
                  </button>
                </div>
                {/*<---------------------->*/}
                <div
                  className={burger_class}
                  style={{
                    backgroundImage: `url("https://4dviewer.prelight3d.co.uk/Resources/sam.png")`,
                    borderRadius: "0.9375em",
                  }}
                >
                  <div style={{ marginLeft: "42em" }}>
                    <img
                      src="https://4dviewer.prelight3d.co.uk/Resources/sam.png"
                      style={{
                        height: "16.875em",
                        width: "36em",
                        backgroundColor: "white",
                        padding: "0.21875em",
                        border: "0.21875em solid #D2188E",
                        borderRadius: "0.9375em",
                      }}
                    />
                    <div
                      style={{
                        marginTop: "-13.5em",
                        height: "13.5em",
                        width: "36em",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: "1",
                          justifyContent: "center",
                          fontSize: 30,
                        }}
                      >
                        <div style={{ marginTop: "0em" }}>
                          Are you sure want to logout?
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: "1",
                          justifyContent: "space-between",
                          marginLeft: "4.375em",
                          marginRight: "4.375em",
                          marginTop: "3.4375em",
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: "#1E1E1E",
                            height: " 2.7083333333333335em",
                            width: "6.25em",
                            borderRadius: "0.625em",
                            border: "0.14583333333333334em solid #D2188E",
                            fontSize: 24,
                            color: "white",
                          }}
                          onClick={() => {
                            handleLogout();
                            updateQuitMenu(true);
                          }}
                        >
                          Yes
                        </button>
                        <button
                          style={{
                            backgroundColor: "#1E1E1E",
                            height: " 2.7083333333333335em",
                            width: "6.25em",
                            borderRadius: "0.625em",
                            border: "0.14583333333333334em solid #D2188E",
                            fontSize: 24,
                            color: "white",
                          }}
                          onClick={() => {
                            updateQuitMenu(true);
                          }}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<---------------------->*/}
              </div>
            }
          </div>
        </div>
      )}
      {handleButton === false ? null : null}
    </div>
  );
};
export default SocialCards;
