import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API } from "../../constant";
import { setToken } from "../../helpers";
import "./index.css";
import "./index.css";
const VendorPage = () => {
  const { isDesktopView } = useScreenSize();
  const navigate = useNavigate();

  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("Kindly select valid vendor");

    const [inputValue, setInputValue] = useState('');
const[check,setCheck]=useState(true);
    const handleChange = (event) => {
      setInputValue(event.target.value);
    };
      function LocalCheck() {
          if(inputValue==="Angels Costume"){
            handleLoad()
              navigate("/signin", { replace: true });
              setIsLoading(false);
          }
          else{
setCheck(false);
setError(error?.message ?? "Kindly select valid vendor");
          }
      }

      const handleLoad = () => {
        setTimeout(() => {  
         window.parent.postMessage({
          sender: "Vendor",
          message: inputValue
      }, '*');
        });
       }
       var [backgroundColor1,setBackgroundColor1]=useState("white")
       var [backgroundColor2,setBackgroundColor2]=useState("white")
       var [backgroundColor3,setBackgroundColor3]=useState("white")
       var [backgroundColor4,setBackgroundColor4]=useState("white")
       function HandleBackgroundColor(name) {
        if(name==="backgroundColor1"){
setBackgroundColor1("#34BCF2");
setBackgroundColor2("white");
setBackgroundColor3("white");
setBackgroundColor4("white");
        }
          if(name==="backgroundColor2"){
            setBackgroundColor1("white");
            setBackgroundColor2("#34BCF2");
            setBackgroundColor3("white");
            setBackgroundColor4("white");
          }
            if(name==="backgroundColor3"){
              setBackgroundColor1("white");
              setBackgroundColor2("white");
              setBackgroundColor3("#34BCF2");
              setBackgroundColor4("white");
            }
              if(name==="backgroundColor4"){
                setBackgroundColor1("white");
                setBackgroundColor2("white");
                setBackgroundColor3("white");
                setBackgroundColor4("#34BCF2");
              }
       }
  return (
    // <Fragment>
    //   <Row align="middle">
    //     <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
    //       <Card title="Vendor">
    //         {check===true ? null: (
    //           <Alert
    //             className="alert_error"
    //             message={error}
    //             type="error"
    //             closable
    //             afterClose={() => setError({inputValue})}
    //           />
    //         ) }
    //         <Form
    //           name="basic"
    //           layout="vertical"
    //           autoComplete="off"
    //         >
    //           <Form.Item
    //             label="Vendor Name"
    //             name="Vendor Name"
    //             rules={[
    //               {
    //                 required: true,
    //                 type: "Vendor Name",
    //               },
    //             ]}
    //           >
    //             <button onClick={()=>{setInputValue("Angels Costume")}}>Angels Costume</button>
    //           </Form.Item>
    //           <Form.Item
    //             name="Vendor Name"
    //             rules={[
    //               {
    //                 required: true,
    //                 type: "Vendor Name",
    //               },
    //             ]}
    //           >
    //           <div>
    //             <button onClick={()=>{setInputValue("Target 3D")}}>Target 3D</button>
    //             </div></Form.Item><Form.Item
    //             name="Vendor Name"
    //             rules={[
    //               {
    //                 required: true,
    //                 type: "Vendor Name",
    //               },
    //             ]}
    //           >
    //             <div>
    //             <button onClick={()=>{setInputValue("Nike")}}>Nike</button>
    //             </div></Form.Item>
    //             <Form.Item
    //             name="Vendor Name"
    //             rules={[
    //               {
    //                 required: true,
    //                 type: "Vendor Name",
    //               },
    //             ]}
    //           >
    //             <div>
    //             <button onClick={()=>{setInputValue("Adidas")}}>Adidas</button>
    //             </div>
    //             </Form.Item>
    //           <Form.Item>
    //             <Button
    //               type="primary"
    //               htmlType="submit"
    //               className="login_submit_btn"
    //               onClick={()=>{
    //                 if(inputValue==="Angels Costume"){LocalCheck();setCheck(true);}else{setCheck(false);}
    //             }}
    //             >
    //               Submit {isLoading && <Spin size="small" />}
    //             </Button>
    //           </Form.Item>
    //         </Form>
    //       </Card>
    //     </Col>
    //   </Row>
    // </Fragment>
      <div style={{height:"100vh",width:"100vw",justifyContent:"center",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",backgroundImage:`url("https://devdencreative.com/DevEnv/4DS%20Test/ARRay/MainPanel.svg")`, backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}>
        <h1 className="heading" style={{fontSize:46}}>SELECT  VENDOR</h1>
        <div>
        <button style={{border:"3.5px solid #34BCF2" ,borderColor:"#34BCF2", borderRadius:"15px" ,height:"75px",width:"350px",borderWidth:"3.5px",margin:"10px",fontSize:26,backgroundColor:backgroundColor1}} onClick={()=>{setInputValue("Angels Costume");HandleBackgroundColor("backgroundColor1");}} className="textontent">Angels Costume</button>
        </div>
        <div>
        <button style={{border:"3.5px solid #34BCF2" ,borderColor:"#34BCF2", borderRadius:"15px" ,height:"75px",width:"350px",borderWidth:"3.5px",margin:"10px",fontSize:26,backgroundColor:backgroundColor2}} onClick={()=>{setInputValue("BirdGang Dance");HandleBackgroundColor("backgroundColor2");}} className="textontent">BirdGang Dance</button>
        </div>
        <div>
        <button style={{border:"3.5px solid #34BCF2" ,borderColor:"#34BCF2", borderRadius:"15px" ,height:"75px",width:"350px",borderWidth:"3.5px",margin:"10px",fontSize:26,backgroundColor:backgroundColor3}} onClick={()=>{setInputValue("Bauhaus Inspirations");HandleBackgroundColor("backgroundColor3");}} className="textontent">Bauhaus Inspirations</button>
        </div>
        <div>
        <button style={{border:"3.5px solid #34BCF2" ,borderColor:"#34BCF2", borderRadius:"15px" ,height:"75px",width:"350px",borderWidth:"3.5px",margin:"10px",fontSize:26,backgroundColor:backgroundColor4}} onClick={()=>{setInputValue("London Punk");HandleBackgroundColor("backgroundColor4");}} className="textontent">London Punk</button>
        </div>
        <div>
        <Button
        className="textontent"
                  style={{border:"3.5px solid #34BCF2" ,borderColor:"#34BCF2", borderRadius:"15px" ,height:"65px",width:"150px",borderWidth:"3.5px",margin:"15px",backgroundColor:"black",color:"white",fontSize:26}}
                  onClick={()=>{
                    if(inputValue==="Angels Costume"){LocalCheck();setCheck(true);}else{setCheck(false);}
                }}
                >
                  Confirm {isLoading && <Spin size="small" />}
                </Button>
        </div>
        {check===true ? null: (
              <p style={{color:"red",fontSize:24}}>{error}</p>
            ) }
      </div>
  );
};

export default VendorPage;
