import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

export function Card({ onClick, title }) {
  const visibility = React.useContext <typeof publicApiType > VisibilityContext;
  // const visible = visibility.useIsVisible(itemId, true);

  return (
    <div
      onClick={() => onClick(visibility)}
      style={{
              marginTop: "0px",
              height: "10em",
              width: "6.7em",
              marginLeft: "2.7em",
              marginRight: "0.875em",
              padding: "3px",
              alignContent:"center"
      }}
      tabIndex={0}
    >
      <div className="card">
        <img
              src={
                title
              }
              
              style={{
                height: "9.65em",
                borderRadius: "5px",
                width: "6.7em"
              }}
              
            ></img>
      </div>
      <div
        style={{
          backgroundColor: "bisque",
          height: "200px",
        }}
      />
    </div>
  );
}
