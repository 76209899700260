import React from "react";
import AppRoutes from "./Routes";

const App = () => {
  return (<>
            <AppRoutes />
            </>
  );
};

export default App;
