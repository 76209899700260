import "./index.css";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
const PrelighT3D = () => {
  const navigate = useNavigate();

  return (
  <button style={{height:"100vh",width:"100vw",justifyContent:"center",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",backgroundColor:"white"}} onClick={()=>{
    navigate("/vendor", { replace: true });
  }}>
    <div style={{height:"250px",width:"450px",justifyContent:"center",display:"flex", borderRadius:"15px",backgroundColor: "#34BCF2",paddingTop:"5px"}}>
      <div style={{height:"240px",width:"440px",justifyContent:"center",display:"flex", borderRadius:"15px",backgroundColor: "white",paddingTop: "15%"}}>
      <h1 style={{fontSize:64}} className="heading">PRELIGHT 3D</h1>
    </div>
    </div>
    <div style={{marginTop:"30px" ,fontSize:24}} className="textontent">Click anywhere to start</div>
  </button>
  );
};

export default PrelighT3D;
