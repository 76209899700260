import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { setToken } from "../../helpers";

const SignIn = () => {
  const { isDesktopView } = useScreenSize();
  const navigate = useNavigate();

  const { setUser } = useAuthContext();

  const isLoading = false;

  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    identifier: "",
    password: "",
  });
  
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    //event.preventDefault();

    const postData = new FormData();
    postData.append("identifier", event.identifier);
    postData.append("password", event.password);

    fetch("https://srv522636.hstgr.cloud/api/auth/local/", {
      method: "POST",
      body: postData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.error) {
          throw data?.error;
        } else {
          // set the token
          setToken(data.jwt);
          handleLoad(data);
  
          // set the user
          setUser(data.user);
  
          message.success(`Welcome back ${data.user.username}!`);
  
          navigate("/SocialCards", { replace: true });
        }
      })
      .catch((error) => {
        console.error(error);
      setError(error?.message ?? "Something went wrong!");
      });
  };
  const handleLoad = (index) => {
    setTimeout(() => {  
     window.parent.postMessage({
      sender: "message",
      message: index
  }, '*');
    }, [100]);
   }
   var [vendorName,setVendorName]=useState("hai");
   var [nameRecived,setNameRecived]=useState(false);
   window.parent.addEventListener('message', e=> {
    setNameRecived(true);
    setVendorName(e.data.message);
      });
  return (
    <div style={{backgroundImage:`url("https://4dviewer.prelight3d.co.uk/Resources/MainPanel.svg")`, backgroundRepeat: 'no-repeat',backgroundSize:"cover",height:"100vh"}}>
    <Fragment>
 <div style={{width:"100vw", justifyContent:"space-between",alignContent:"space-between",display:"flex",flexDirection:"row",alignItems:"space-between"}}>
  <button style={{border:"3.5px solid #D2188E" ,borderColor:"#D2188E", borderRadius:"15px" ,height:"85px",width:"85px",borderWidth:"3px",margin:"10px",backgroundColor:"#1E1E1E"}} onClick={()=>{navigate("/vendor", { replace: true });}}><img src="https://4dviewer.prelight3d.co.uk/Resources/back.png" style={{height:"50px",width:"50px"}}></img></button>
 </div>
      {nameRecived===true ? (
        <div style={{height:"80vh",width:"100vw",justifyContent:"center",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
      <div style={{border:"3.5px solid #D2188E",borderColor:"#D2188E",borderRadius:"15px",margin:"20px"}}>
         <img src="https://4dviewer.prelight3d.co.uk/Resources/logo1.png" alt="1" style={{height:"125px",width:"200px",borderRadius:"15px",padding:"3px"}}/>
      </div>
            <Form
              name="basic"
              layout="vertical"
              onFinish={handleSubmit}
              autoComplete="off"
                style={{fontSize:24}}
            >
              <Form.Item
                label= { <p className="textontent" style={{fontSize:22,marginBottom:"-10%"}}>Vendor Name</p> }
                name="Vendor"
              >
                <Input style={{borderColor:"#D2188E", borderRadius:"15px" ,height:"60px",width:"450px",borderWidth:"3px",fontSize:22}} disabled="true" placeholder={vendorName} />
              </Form.Item>
              <Form.Item
                label={ <p className="textontent" style={{fontSize:22,marginBottom:"-10%"}}>Email ID</p> }
                name="identifier"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input style={{borderColor:"#D2188E", borderRadius:"15px" ,height:"60px",width:"450px",borderWidth:"3px",fontSize:22}} placeholder="Email address" onChange={handleInputChange}/>
              </Form.Item>

              <Form.Item
                label={ <p className="textontent" style={{fontSize:22,marginBottom:"-10%"}}>Password</p> }
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password style={{borderColor:"#D2188E", borderRadius:"15px" ,height:"60px",width:"450px",borderWidth:"3px",fontSize:22}} placeholder="Password" onChange={handleInputChange}/>
              </Form.Item>

            {error ? (
              <p style={{color:"red",marginLeft:"15%"}}>{error}</p>
            ) : null}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="textontent"
                  style={{borderColor:"#D2188E", borderRadius:"15px" ,height:"60px",width:"160px",marginBottom:"10 px",borderWidth:"3px",backgroundColor:"black",color:"white",fontSize:18,marginLeft:"31%"}}
                >
                  <div className="textontent" style={{fontSize:24}}>
                  Login
                  </div> {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
            </div>): null }
      
    </Fragment>
    </div>
//     <div>
// <div style={{width:"100vw", justifyContent:"space-between",alignContent:"space-between",display:"flex",flexDirection:"row",alignItems:"space-between"}}>
//   <button style={{borderColor:"#34BCF2", borderRadius:"15px" ,height:"60px",width:"60px",borderWidth:"5px",margin:"10px"}}></button>
// </div>
//     <div style={{height:"90vh",width:"100vw",justifyContent:"center",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",backgroundColor:"white"}}>
//       <div style={{backgroundColor:"#34BCF2",borderRadius:"15px",margin:"20px"}}>
//         <img src="https://i.imgur.com/Mx7dA2Y.jpg" alt="1" style={{height:"200px",width:"200px",borderRadius:"15px",padding:"3px"}}/>
//       </div>
//       <div style={{marginLeft:"-100px",marginBottom:"3px"}}>
//         Vendor Name :
//       </div>
//       <div>
//       <Input disabled="true" placeholder={vendorName} />
//       </div>
//       <div style={{marginLeft:"-140px",marginBottom:"3px"}}>
//         Email ID:
//       </div>
//       <div>
//       <Input name="identifier" placeholder="Email address" onChange={handleInputChange}/>
//       </div>
//       <div style={{marginLeft:"-140px",marginBottom:"3px"}}>
//       Password:
//       </div>
//       <div>
//       <Input.Password name="password" placeholder="Password" onChange={handleInputChange}/>
//       </div>
//       <div>
//       <Button
//       type="primary"
//                     style={{borderColor:"#34BCF2", borderRadius:"15px" ,height:"60px",width:"160px",borderWidth:"5px",margin:"15px",backgroundColor:"black",color:"white",fontSize:18}}
//                     onClick={()=>{
//                       console.log(0);
//                       handleSubmit();
//                   }}
//                   >
//                     Login {isLoading && <Spin size="small" />}
//                   </Button>
//       </div>
//     </div>
//     </div>
  );
};

export default SignIn;
